<template>
<div class="vol_class">

    <button class="btn btn-primary"

            @click="volChange(1)"

    >+</button>

    <span>{{level}}</span>
    <button class="btn btn-primary"
            @click="volChange(-1)"
    >-</button>

    <button
        :class="[muted ? 'btn btn-danger':'btn btn-success']"
        @click="toggleMute()"
    >
        {{muted ? 'Muted':'On Air'}}

    </button>

    <span>{{data.title}}</span>
</div>
</template>

<script>

    export default {
        name: 'my-channel',
        props: {
            data: { required: true, type: Object },
              },
        data(){
            return{
                id:this.data.id,
                muted:this.data.muted,
                level:this.data.level,
                interval: null,
            }
        },
        methods:{
            volChange(v){
                this.level+=v;
                if (this.level<0) this.level=0;
                if (this.level>100) this.level=100;
            },
            repeatChange(v){
                this.interval = setInterval(this.volChange(v), 300);
            },
            toggleMute(){
                this.muted=!this.muted;
                this.$emit('toggleMuted', {muted:this.muted, id:this.id});
            },
        },
    };
</script>
<style scoped>
    .vol_class{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        font-size: x-large;
    }
    button{
        width: 5rem;
        height: 3rem;
        margin: 1rem 0;
        font-size: large;
    }
    span{
        margin: 1rem 0;

    }

</style>
