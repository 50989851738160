<template>
    <div class="d-flex  justify-content-center">
        <div class="card " style="width: 80%;">
            <h5 class="card-header">Питание</h5>
            <div class="card-body">
                <div class="d-flex form-check form-switch">
                    <input class="form-check-input btn-lg " type="checkbox" id="power1">
                    <label class="fs-4" for="power1">Видеостена</label>
                </div>

                <div class="d-flex form-check form-switch">
                    <input class="form-check-input btn-lg " type="checkbox" id="power2">
                    <label class="fs-4" for="power2">Камеры</label>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "my-settings",
    };
</script>

<style scoped>
    input {
        margin: 1rem;
        display: flex;
        align-items: center;
    }

    label {
        display: flex;
        align-items: center;
    }
</style>
