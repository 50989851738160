<template>
    <div id="app">
        <div class="my-header">
            <h2>Конференц-зал</h2>
            <h4>{{ header }}</h4>
<!--            <hr/>-->
        </div>
        <div class="my-component">
        <component :is="componentName"></component>
        </div>
        <my-footer-nav :dataMenu="dataMenu" @sendPage="changePage"></my-footer-nav>
    </div>
</template>

<script>
    import MyFooterNav from "./components/UI/FooterNav";
    import Main from "./pages/Main";
    import Volume from "./pages/Volume";
    import Video from "./pages/Video";
    import Settings from "./pages/Settings";

    export default {
        name: "my-app",
        components: {
            MyFooterNav, Main, Settings, Video, Volume,
        },
        created() {
            document.title = "Конференц-зал";
            this.componentName = 'Main';
        },
        data() {
            return {
                componentName: 'Main',
                header: "",
                dataMenu: [
                    {button: "Main", header: "Главная", link: "main", icon: 'bi bi-house-fill', page: 'Main'},
                    {
                        button: "Video",
                        header: "Коммутация видеосигнала",
                        link: "video",
                        icon: 'bi bi-display-fill',
                        page: 'Video'
                    },
                    {
                        button: "Volume",
                        header: "Управление громкостью",
                        link: "volume",
                        icon: 'bi bi-music-note-beamed', page: 'Volume'
                    },
                    {
                        button: "Settings",
                        header: "Настройки",
                        link: "settings",
                        icon: 'bi bi-gear-fill',
                        page: 'Settings'
                    },
                ],
            };
        },
        // watch: {
        //     "$route.name": {
        //         handler: function (p) {
        //             console.log("Name =", p);
        //             this.dataMenu.forEach((el) => {
        //                 // console.log("El =", el.link);
        //                 if (el.link === p) this.header = el.header;
        //             });
        //         },
        //     },
        // },

        methods: {
            changePage(b) {
                console.log('changePage', b);
                this.componentName = b.page;
                this.header = b.header;
            }
        },
        watch: {
            componentName: function (name) {
                this.dataMenu.forEach((el) => {
                    if (el.button === name) this.header = el.header;
                });

                console.log('watch', name);
            }
        }
    };
</script>

<style>
    #app {
        font-family: Roboto, "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin: 0;
        padding: 0;
        /* margin-top: 30px; */
        /* margin-bottom: 10px; */
    }
    .my-header{
        position: fixed;
        top: 0;
        width: 100%;
        height: 5rem;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        /*display: flex;*/
        overflow-x: auto;
        z-index: 9999;
    }
    .my-component{
        margin-top: 6rem;
    }

</style>
