<template>
    <h3>MAIN</h3>
</template>

<script>
    export default {
        name: "my-main"
    }
</script>

<style scoped>

</style>
