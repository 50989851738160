<template>

    <div class="vol_container" v-if="currentVolPage==='volumeMic'">
        <my-channel v-for="item, index in volumeMic" :key="index" :data=item
                    @toggleMuted="toggleMuted"></my-channel>
    </div>

    <div class="vol_container" v-else-if="currentVolPage==='volumeLine'">
        <my-channel v-for="item, index in volumeLine" :key="index" :data=item
                    @toggleMuted="toggleMuted"></my-channel>
    </div>
    <div class="vol_container" v-else-if="currentVolPage==='volumeMain'">
        <my-channel v-for="item, index in volumeMain" :key="index" :data=item
                    @toggleMuted="toggleMuted"></my-channel>
    </div>



    <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group"
         @change="volTypeChange($event)">
        <input type="radio" class="btn-check" name="btnradio" id="volumeMic" autocomplete="off" checked>
        <label class="btn btn-outline-primary" for="volumeMic">Микрофоны</label>

        <input type="radio" class="btn-check" name="btnradio" id="volumeLine" autocomplete="off">
        <label class="btn btn-outline-primary" for="volumeLine">Линейны</label>

        <input type="radio" class="btn-check" name="btnradio" id="volumeMain" autocomplete="off">
        <label class="btn btn-outline-primary" for="volumeMain">Выходы</label>
    </div>

</template>

<script>

    export default {
        name: 'my-volume',

        data() {
            return {
                volumeData: [
                    {id: 1, title: "Mic 1", level: 70, muted: false},
                    {id: 2, title: 'Mic 2', level: 60, muted: true},
                    {id: 3, title: 'Mic 3', level: 50, muted: false},
                    {id: 4, title: 'Line 1', level: 20, muted: false},
                    {id: 5, title: 'Line 2', level: 30, muted: true},
                    {id: 6, title: 'VC', level: 30, muted: true},
                    {id: 7, title: 'Main', level: 80, muted: false},
                ],
                volumeMic: [
                    {id: 1, title: "Mic 1", level: 70, muted: false},
                    {id: 2, title: 'Mic 2', level: 60, muted: true},
                    {id: 3, title: 'Mic 3', level: 50, muted: false},
                ],
                volumeLine: [
                    {id: 4, title: 'Line 1', level: 20, muted: false},
                    {id: 5, title: 'Line 2', level: 30, muted: true},
                    {id: 6, title: 'VC', level: 30, muted: true},
                ],
                volumeMain: [
                    {id: 6, title: 'Record', level: 80, muted: false},
                    {id: 7, title: 'Main', level: 80, muted: false},
                ],
                currentVolPage: 'volumeMic',
                // allVolume:{
                //     'volumeMic':this.volumeMic,
                //     'volumeLine':this.volumeLine,
                //     'volumeMain':this.volumeMain,
                // }
            }
        },

        methods: {
            toggleMuted(v) {
                this.volumeData.filter((elem) => {
                    if (elem.id === v.id) elem.muted = v.muted;
                })
            },
            volTypeChange(ev) {
                this.currentVolPage = ev.target.id;
            },
        }
    }
</script>


<style scoped>
    .vol_container {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
</style>
