<template>
    <nav class="nav">
        <a
                v-for="(b, index) in dataMenu"
                :key="index"
                @click="
                    active = b.link;
                    $emit('sendPage', b) "
                class="nav__link"
        >
            <i :class="b.icon +' nav__icon '"></i>
            <span class="nav__text active">{{ b.button }}</span>
        </a>

    </nav>
</template>

<script>
    export default {
        name: "my-footer-nav",
        props: {
            dataMenu: {
                required: true,
            },
            setPage: {
                required: false,
            },
        },
        data() {
            return {
                active: "/",
            };
        },
        methods: {
            sendPage(b) {
                this.active = b.link;
                this.$emit('sendPage', b)
            }
        },
        // watch: {
        //     setPage: function (name) {
        //         console.log('WATCH:',name);
        //     }
        // }
    }
    ;
</script>

<style scoped>
    button {
        padding: 1rem 1rem;
        margin-left: 1rem;
    }

    .nav {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 5rem;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        display: flex;
        overflow-x: auto;
    }

    a {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
    }

    a .nav__active {
        color: red;
        font-weight: bold;
    }

    .nav__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        min-width: 50px;
        overflow: hidden;
        white-space: nowrap;
        /*font-family: sans-serif;*/
        font-size: medium;
        color: #444444;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        transition: background-color 0.1s ease-in-out;
    }

    /*.nav__link:hover {*/
    /*  background-color: #eeeeee;*/
    /*}*/

    /*.nav__link --active {*/
    /*    color: red;*/

    /*}*/

    .nav__active {
        color: blue;
    }


    .nav__icon {
        font-size: 1.5rem;
    }
</style>
